<template>
	<div class="personal-details-contend">
		<div class="row">
		<b-col
			cols="12" class="p-0 m-0"
		>
			<div class="success-message mt-5 pt-2">
				<h2 class="mb-3 font-weight-bolder text-center">Thank you!</h2>
				<div class="cheked m-auto d-flex ">
					<feather-icon
						icon="CheckIcon"
						stroke="#25C348"
						size="70"
					/>
				</div>
			</div>
			<h2 class="submit-message font-weight-bolder text-center mt-2">You’ve successfully completed your <br/> profile. <br/>
				We’ll get back to you shortly
			</h2>
		</b-col>
		<b-col
			cols="6" class="p-0 m-auto"
		>
			<b-button
				variant="primary"
				block
				type="submit"
				class="p-2 mt-4"
				@click="submitForm"
			>
				Done
			</b-button>
		</b-col>
		</div>
	</div>
</template>

<script>
export default {
	setup(props,{ emit }) {
		const submitForm = () => {
			emit('submitForm', 6);
		}
		return {
			submitForm
		}
	}
}
</script>