<template>
  <div class="personal-details-contend">
    <h2 class="fs-1 font-weight-bolder pb-2">Identity Proofs</h2>
    <validation-observer ref="identityProofs" v-slot="{ handleSubmit }">
      <b-form
        class="auth-register row"
          @submit.prevent="handleSubmit(submitForm)"
      >
        <b-col
        cols="12"
        class="p-0 row d-flex align-content-start m-0"
        >
          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1"
          >
            <InputField :id="'aadhar-number'" :placeholder="'Enter identity name '" :describedby="'aadhar-number-feedback'" :label="'Aadhaar number *'" :name="'aadhar number'" :rules="{ required: true, integer: true}" :modelValue="identityProofsFormData.aadhaar" @input="val => identityProofsFormData.aadhaar = val" :type="'number'" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1 d-flex "
          >
            <validation-provider
              :rules="{required: true}"
              name="Upload Aadhar"
              class="w-100"
              v-slot="validationContext"
            >
              <b-form-group
                label="Upload Aadhaar *"
                label-for="uploadAadhar"
              >
                <b-form-file
                  size="lg"
                  id="uploadAadhar"
                  v-model="aadhaarFile"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose a file"
                  accept="image/jpeg,image/png,application/pdf"
                  aria-describedby="uploadAadhar"
                  @change="changeAadhar"
                ></b-form-file>
                <b-form-invalid-feedback id="uploadAadhar">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1"
          >
            <InputField :id="'pan-number'" :placeholder="'Enter identity name '" :describedby="'pan-number-feedback'" :label="'PAN number *'" :name="'Pan number'" :rules="{ required: true}" :modelValue="identityProofsFormData.pan" @input="val => identityProofsFormData.pan = val" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1 d-flex"
          >
          <validation-provider
              :rules="{required: true}"
              name="Pan Aadhar"
              class="w-100"
              v-slot="validationContext"
            >
              <b-form-group
                label="Upload PAN *"
                label-for="UploadPan"
              >
                <b-form-file
                  size="lg"
                  id="panAadhar"
                  v-model="panFile"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose a file"
                  accept="image/jpeg,image/png,application/pdf"
                  aria-describedby="panAadhar"
                  @change="changePan"
                ></b-form-file>
                <b-form-invalid-feedback id="panAadhar">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
          </validation-provider>
          </b-col>
        </b-col>

        <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1"
          >
            <InputField :id="'other-doc-name'" :placeholder="'Other documents name '" :describedby="'other-doc-name-feedback'" :label="'Other documents name'" :name="'Other documents name'" :modelValue="identityProofsFormData.otherDocName" @input="val => identityProofsFormData.otherDocName = val" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1 d-flex"
          >
          <validation-provider
              name="Other documents name"
              class="w-100"
              v-slot="validationContext"
            >
              <b-form-group
                label="Upload other"
                label-for="UploadOther"
              >
                <b-form-file
                  size="lg"
                  id="other"
                  v-model="otherFile"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose a file"
                  accept="image/jpeg,image/png,application/pdf"
                  aria-describedby="other"
                  @change="changeOther"
                ></b-form-file>
                <b-form-invalid-feedback id="other">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
          </validation-provider>
        </b-col>
        
        <b-col cols="12" class="p-0 row m-0 mt-sm-auto">
          <b-col
          md="5"
          class="mt-auto m-0"
          >
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
              class="p-2"
            >
              Submit
            </b-button>
          </b-col>
        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import InputField from '@/views/components/form/InputField.vue';
import { ref } from '@vue/composition-api'
export default {
  components: {
    InputField
  },
  props: {
    identityProofsFormData: {
      type: Object,
      required: true
    }
  },
  setup(props,{ emit }) {
    const aadhaarFile = ref(null)
    const panFile = ref(null)
    const otherFile = ref(null)
    const invalid = ref(false);
    const username = ref('');
    const submitForm = () => {
      emit('submitForm', 5);
    }

    function getBase64 (file, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(file);
    }

    const changeAadhar = (e) => {
      props.identityProofsFormData.aadhaarFile = e.target.files[0];
      // getBase64(e.target.files[0], function(base64Data){
      //   props.identityProofsFormData.aadhaarFile = base64Data
      // });
    }

    const changePan = (e) => {
      props.identityProofsFormData.panFile = e.target.files[0]
      // getBase64(e.target.files[0], function(base64Data){
      //   props.identityProofsFormData.panFile = base64Data
      // });
    }


    const changeOther = (e) => {
      props.identityProofsFormData.otherDocFile = e.target.files[0]
    }

    const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    }
    return {
      submitForm,
      username,
      invalid,
      getValidationState,
      changePan,
      changeAadhar,
      aadhaarFile,
      panFile,
      otherFile,
      changeOther
    }
  }
}
/* eslint-disable global-require */
</script>
