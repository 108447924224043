<template>
  <div class=" personal-details-contend">
    <h2 class="fs-1 font-weight-bolder pb-2">Address Details</h2>
    <validation-observer ref="addressDetails" v-slot="{ handleSubmit }">
      <b-form
        class="auth-register row "
        @submit.prevent="handleSubmit(submitForm)"
      >
        <b-col
          cols="12" class="p-0 row d-flex align-content-start m-0"
        >
          <b-col
            sm="12"
            md="12"
            class="mx-auto mb-1"
          >
            <InputField :id="'addrLine1'" :placeholder="'Flat no / House no'" :describedby="'addrLine1-feedback'" :label="'Line 1 *'" :name="'Line 1'" :rules="{ required: true}" :modelValue="addressFormData.addrLine1" @input="val => addressFormData.addrLine1 = val" />
          </b-col>
          <b-col
            sm="12"
            class="mx-auto mb-1"
          >
            <InputField :id="'addrLine2'" :placeholder="'Street name / Bulding no / Plot  no'" :describedby="'addrLine2-feedback'" :label="'Line 2 *'" :name="'Line 2'" :rules="{ required: true}" :modelValue="addressFormData.addrLine2" @input="val => addressFormData.addrLine2 = val" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mb-1"
          >
            <InputField :id="'city'" :placeholder="'Enter city name'" :describedby="'city-feedback'" :label="'City *'" :name="'City'" :rules="{ required: true}" :modelValue="addressFormData.city" @input="val => addressFormData.city = val" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1"
          >
            <InputField :id="'zip-code'" :placeholder="'Enter postal / zip code'" :describedby="'pincode-feedback'" :label="'Postal / Zip code *'" :name="'zip-code'" :rules="{ required: true }" :modelValue="addressFormData.pincode" @input="val => addressFormData.pincode = val" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mx-auto mb-1"
          >
            <SelectField :id="'country'" :placeholder="'Enter country name'" :describedby="'country-feedback'" :label="'Country *'" :name="'country'" :rules="{ required: true}" :modelValue="addressFormData.country" @input="countryInput" :options="allCountry" :isVSelect="true" :getValue="'value'" />
          </b-col>
          <b-col
            sm="12"
            md="6"
            class="mb-1"
          >
            <SelectField :id="'state'" :placeholder="'Enter state name'" :describedby="'state-feedback'" :label="'State *'" :name="'State'" :rules="{ required: true}" :modelValue="addressFormData.state" @input="val => addressFormData.state = val"  :options="allState" :isVSelect="true" :getValue="'value'"  />
          </b-col>
        </b-col>

        <b-col cols="12" class="p-0 row m-0 mt-sm-auto">
          <b-col
          md="5"
          class="mt-auto m-0 col-md-5"
          >
            <b-button
              variant="primary"
              block
              type="submit"
              class="p-2"
            >
              SAVE & COUNTINUE
            </b-button>
          </b-col>
        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import { ref, computed } from '@vue/composition-api'
import InputField from '@/views/components/form/InputField.vue';
import { Country, State }  from 'country-state-city';
import SelectField from '@/views/components/form/SelectField.vue';
import vSelect from 'vue-select'
export default {
  components: {
    InputField,
    vSelect,
    SelectField
  },

  props: {
    addressFormData:{
      type: Object,
      required: true
    }
  },
  setup(props,{ emit }) {
    const allCountry = Country.getAllCountries().map(el => { return {name:el.name, value:el.isoCode}});
    const allState = ref([{name:'Other', value:'0'}])
    const submitForm = () => {
      emit('submitForm', 4);
    }
    
    const countryInput = (val) => {
      props.addressFormData.country = val
      emit('changeState')
      console.log(props.addressFormData)
      let statValue = State.getStatesOfCountry(val.value).map(el => { return {name:el.name, value:el.isoCode}});
      if(statValue.length>0) {
        allState.value = statValue
      } else {
        allState.value = [{name:'Other', value:'0'}]
      }
    }
    return {
      submitForm,
      allCountry,
      countryInput,
      allState
    }
  }
}
/* eslint-disable global-require */
</script>
