<template>
  <div class="personal-details-contend">
    <h2 class="fs-1 font-weight-bolder pb-2">Client Profile</h2>
     <validation-observer ref="clientProfile" v-slot="{ handleSubmit }">
      <b-form
        class="auth-register row"
        @submit.prevent="handleSubmit(submitForm)"
      >
      <b-col
       cols="12"
       class=" p-0"
       >
        <b-col
          cols="12"
          class="mx-auto mb-2"
        >
          <InputField :id="'capital-to-invest'" :placeholder="'Capital amount to invest'" :describedby="'capital-to-invest-feedback'" :label="'Capital  amount to invest *'" :name="'Capital amount to invest'" :rules="{ required: true, integer: true}" :modelValue="clientFormData.capitalToInvest" @input="val => clientFormData.capitalToInvest = val" :type="'number'" />
        </b-col>

        <b-col
          cols="12"
          class="mx-auto mb-2"
        >
        <SelectField
          :id="'Risk-appetite'"
          :placeholder="''"
          :describedby="'Risk-appetite-feedback'"
          :label="'Risk-appetite'"
          :name="'Risk-appetite'"
          :rules="{ required: true }"
          :modelValue=" clientFormData.riskAppetite"
          @input="(val) => ( clientFormData.riskAppetite = val)"
          :options="options"
        />
        </b-col>
      </b-col>
        
        <b-col cols="12" class="p-0 row m-0 mt-sm-auto">
          <b-col
          md="5"
          class="mt-auto m-0"
          >
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
              class="p-2 "
            >
              SAVE & COUNTINUE
            </b-button>
          </b-col>
        </b-col>
      </b-form>
     </validation-observer>
  </div>
</template>


<script>
import { ref } from '@vue/composition-api';
import InputField from '@/views/components/form/InputField.vue';
import SelectField from '@/views/components/form/SelectField.vue';
import { integer } from 'vee-validate/dist/rules';
export default {
  components: {
    InputField,
    SelectField
  },
  props: {
    clientFormData:{
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const options = [
      { value: null, text: 'Please select an option' },
      { value: "Low", text: 'Low' },
      { value: "Medium", text: 'Medium' },
      { value: "High", text: 'High' },
    ]
    const invalid = ref(false);
    const submitForm = () => {
      emit('submitForm', 3);
    }
    return {
      options,
      submitForm,
      invalid
    }
  }
}
/* eslint-disable global-require */
</script>
