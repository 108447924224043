<template>
    <div class="personal-details-contend" >
      <h2 class="fs-1 font-weight-bolder pb-2">Personal Details</h2>
        <p class="pb-1">Please provide below details, required by your financial advisor.</p>
        <validation-observer ref="personalDetails" v-slot="{ handleSubmit }" class="auth-register-form  d-block">
          <b-form
            class="row needs-validation h-100"
            @submit.prevent="handleSubmit(submitForm)"
          >
          <b-col cols="12" class="p-0 row d-flex align-content-start m-0">
            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'first-name'" :placeholder="'First Name'" :describedby="'first-name-feedback'" :label="'First Name *'" :name="'first Name'" :rules="{ required: true}" :modelValue="personalFormData.firstName" @input="val => personalFormData.firstName = val" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'late-name'" :placeholder="'Last Name'" :describedby="'last-name-feedback'" :label="'Last Name *'" :name="'Last Name'" :rules="{ required: true}" :modelValue="personalFormData.lastName" @input="val => personalFormData.lastName = val" />
            </b-col>

            <b-col
              cols="12"
              class="mx-auto mb-1"
            >
              <InputField :id="'email'" :placeholder="'Email'" :describedby="'email-feedback'" :label="'Email *'" :name="'Email'" :rules="{ required: true, email: true}" :modelValue="personalFormData.email" @input="val => personalFormData.email = val" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto  mb-1"
            >
            	<b-form-group
                label="Country Code *"
                label-for="phone"
                class="mt-0"
              >
              <div class="">
                <vue-country-code
                  id="country-code"
                  class="w-100 "
                  :enabledFlags="true"
                  :enabledCountryCode="true"
                  :disabledFetchingCountry="true"
                  defaultCountry="IN"
                  @onSelect="onSelect"
                  :preferredCountries="['vn', 'us', 'gb']">
                </vue-country-code>
              </div>
            </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'phone'" :placeholder="'Phone'" :describedby="'phone-feedback'" :label="'Phone *'" :name="'Phone'" :rules="{ required: true, integer: true}" :modelValue="personalFormData.phone" @input="val => personalFormData.phone = val" :type="'number'"/>
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-2"
            >
             <!--  <SelectField
                :id="'Preferred-mode'"
                :placeholder="'Preferred Mode'"
                :describedby="'phone-feedback'"
                :label="'Preferred mode for recommendation *'"
                :name="'Preferred Mode'"
                :rules="{ required: true}"
                :modelValue="personalFormData.preferredMode"
                @input="val => personalFormData.preferredMode = val" :options="options"
              /> -->
            </b-col>

             <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <!-- <InputField :id="'Preferred-mode-user-name'" 
                :placeholder="`${(personalFormData.preferredMode == 'telegram') ? 'Telegram User Name' : 'Whatsapp phone'}`"
                :describedby="'phone-feedback'" :label="`${(personalFormData.preferredMode== 'telegram') ? 'Telegram User Name' : 'Whatsapp phone'}`" 
                :name="`${personalFormData.preferredMode == 'telegram' ? 'Telegram User Name' : 'Whatsapp phone'}`"
                :rules="{ required: true}" :modelValue="personalFormData.preferredModeUsername" @input="val => personalFormData.preferredModeUsername = val" /> -->
            </b-col>
          </b-col>

          <b-col cols="12" class="p-0 row m-0 mt-sm-auto">
            <b-col md="5" class="" >
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
                class="p-2 mt-lg-2"
              >
                SAVE & COUNTINUE
              </b-button>
            </b-col>
          </b-col>
          </b-form>
        </validation-observer>
    </div>
</template>


<script>
import { ref, toRef, reactive } from '@vue/composition-api'
import InputField from '@/views/components/form/InputField.vue';
import VueCountryCode from "vue-country-code";
import SelectField from '@/views/components/form/SelectField.vue';

export default {
  components: {
    InputField,
    VueCountryCode,
    SelectField
  },
  props:{
    personalFormData:{
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const options = [
      // { value: 'telegram', text: 'Telegram' },
      { value: 'whatsapp', text: 'Whatsapp' },
    ]
    const personalDetails = ref(null);
    const invalid = ref(false);

    const submitForm = () => {
      emit('submitForm', 2);
    }
    const getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    }

    const onSelect = ({name, iso2, dialCode}) => {
      props.personalFormData.pcc = `${dialCode}`
    }

    return {
      onSelect,
      personalDetails,
      submitForm,
      invalid,
      getValidationState,
      options
    }
  }
}
/* eslint-disable global-require */
</script>
