<template>
  <div class="h-100 personal-details">
		<b-col
			cols="12"
			class="px-5 pb-5"
			>
      <b-link class="brand-logo mb-5 d-none d-lg-block ">
      <img src="@/assets/images/signup/kyc_logo.png" alt="logo" width="143"/>
      </b-link>
      <b-link class="brand-logo  d-block d-lg-none ">
				<img src="@/assets/images/signup/dark_kyc_logo.png" alt="logo" width="143"/>
			</b-link>
    </b-col>
    <b-col
      cols="12"
      class="mx-auto mt-5 pt-4 text-light few-click px-5 d-none d-lg-block h-auto"
      >
      <h1 class="d-block text-light font-weight-bolder pb-1 mt-5">Few click away on<br/> KYC.</h1>
      <p class="text-light">Start your KYC in minutes. <br/>save time and money</p>
    </b-col>
    <b-col
      cols="12"
      class="m-0 mt-auto personal-images d-none d-lg-block h-auto"
      >
      <img class="" src="@/assets/images/signup/Get_started.png" alt="logo" />
    </b-col>
  </div>
</template>

<script>
export default {
}
/* eslint-disable global-require */
</script>

<style lang="scss">
.few-click {
  font-size: 32px;
  p {
    color: #B2CFFF;
    font-size: 18px;
  }
}
</style>
