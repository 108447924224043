<template>
  <div class="personal-details-contend">
    <b-col cols="12" class="px-5 pt-5">
      <div class="d-flex justify-content-between align-items-center">
        <img
          src="@/assets/images/signup/dark_kyc_logo.png"
          alt="logo"
          width="143"
        />
        <div class="cursor-pointer" @click="$emit('changeTab', currentStep - 1)">
          <feather-icon icon="ArrowLeftIcon" stroke="#6e6b7b" size="25" />
        </div>
      </div>
    </b-col>
    <b-col cols="12" class="px-5 py-5">
      <div
        v-for="(item, index) in setp"
        :key="index"
        class="main-navigation d-flex align-items-start"
      >
        <div
          class="register-navigation mb-4"
          :class="[
            item.id == currentStep ? 'active' : '',
            currentStep > item.id ? 'submited' : '',
          ]"
        >
          <span v-if="currentStep > item.id">
            <feather-icon icon="CheckIcon" stroke="white" size="16" />
          </span>
          <span v-else>
            {{ item.id }}
          </span>
        </div>
        <div
          class="pl-3 show-name"
          :class="[
            item.id == currentStep ? 'active' : '',
            currentStep > item.id ? 'submited' : '',
          ]"
        >
          {{ item.name }}
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
export default {
  props: {
    currentStep: {
      required: true,
      default: 0,
    },
    totalStep: {
      required: true,
      default: 0,
    },
    setp: {
      required: true,
    },
  },
};
</script>