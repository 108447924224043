<template>
  <div class="auth-wrapper auth-v2 bg-light ragister-page">
    <b-row class="auth-inner p-2 m-0">
      <b-col
        lg="4"
        class="d-flex w-100"
      >
        <PersonalDetailsTab class="personal-detail-tab w-100" v-if="currentStep == 1"/>
        <Navigation class="navigation w-100" :currentStep="currentStep" :totalStep="totalStep" :setp="setp" v-else @changeTab="(tabId) => currentStep = tabId"/>
      </b-col>
      <b-col
        lg="8"
        class="bg-light px-2 pt-5 px-xl-5"
      >
        <p class="text-right mb-2"></p>
        <PersonalDetailsForm @submitForm="submitForm" v-if="currentStep == 1" :personalFormData="personalFormData" />
        <ClientProfile @submitForm="submitForm" v-if="currentStep == 2" :clientFormData="clientFormData" />
        <AddressDetails @submitForm="submitForm" v-if="currentStep == 3" :addressFormData="addressFormData" @changeState="addressFormData.state = ''" />
        <IdentityProofs @submitForm="submitForm" v-if="currentStep == 4" :identityProofsFormData="identityProofsFormData" />
        <SuccessfullySubmit @submitForm="submitForm" v-if="currentStep == 5" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PersonalDetailsTab from './KYCSignUp/PersonalDetailsTab'
import PersonalDetailsForm from './KYCSignUp/PersonalDetailsForm.vue'
import ClientProfile from './KYCSignUp/ClientProfile.vue'
import Navigation from './KYCSignUp/Navigation.vue'
import AddressDetails from './KYCSignUp/AddressDetails.vue'
import IdentityProofs from './KYCSignUp/IdentityProofs.vue'
import SuccessfullySubmit from './KYCSignUp/SuccessfullySubmit.vue'
import { reactive, ref, onMounted, computed, inject } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// import router from 'vue-router'

export default {
  components: {
    AddressDetails,
    Navigation,
    ClientProfile,
    PersonalDetailsTab,
    PersonalDetailsForm,
    IdentityProofs,
    SuccessfullySubmit,
  },
  setup(props,context) {
    const $http = inject('$http');
    const router = context.root.$router;
    const setp = [
        { name:'Personal Details', id: 1},
        { name:'Client Profile', id: 2},
        { name:'Address Details', id: 3},
        { name:'Identity Proofs', id: 4},
        { name:'Done', id: 5},
      ];
    const personalFormData = reactive({
      firstName : null,
      lastName : null,
      email : null,
      pcc: null,
      phone : null,
      preferredMode : 'whatsapp',
      preferredModeUsername : null,
    })

    const addressFormData = reactive({
      addrLine1: null,
      addrLine2: null,
      city: null,
      state: null,
      country: null,
      pincode: null,
    })

    const identityProofsFormData = reactive({
      aadhaar: null,
      pan: null,
      aadhaarFile: null,
      panFile: null,
      otherDocName: null,
      otherDocFile: null
    })

    const clientFormData = reactive({
      riskAppetite: null,
      capitalToInvest: null
    })

    const currentStep= ref(1)
    const totalStep = 5

    const submitForm = async (tabId) => {
      if(tabId == 5) {

        let aadhaarDocID = null;
        let panDocID = null;
        let otherDocID = null;
        if(identityProofsFormData.aadhaarFile && subscriptionsDetails.value) {
          aadhaarDocID = await fileUpload('AADHAAR', identityProofsFormData.aadhaarFile, subscriptionsDetails.value.subscriptionID)
          console.log('aadhaarDocID -->', aadhaarDocID);
        } else {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: "Subscriptions Details not found for upload doc",
              closeOnClick: false,
              timeout: false,
            },
          })
          return false;
        }

        if(identityProofsFormData.panFile && subscriptionsDetails.value) {
          panDocID = await fileUpload('PAN', identityProofsFormData.panFile, subscriptionsDetails.value.subscriptionID)
          console.log('panDocID -->', panDocID);
        } else {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: "Subscriptions Details not found for upload doc",
              closeOnClick: false,
              timeout: false,
            },
          })
          return false;
        }

        if(identityProofsFormData.otherDocFile && subscriptionsDetails.value) {
          otherDocID = await fileUpload('OTHER', identityProofsFormData.otherDocFile, subscriptionsDetails.value.subscriptionID)
        }


        const data = {...personalFormData, ...clientFormData, capitalToInvest: parseInt(clientFormData.capitalToInvest)}
        data.pan = identityProofsFormData.pan
        data.aadhaar = identityProofsFormData.aadhaar
        data.otherDocName = identityProofsFormData.otherDocName
        data.aadhaarDocID = aadhaarDocID
        data.panDocID = panDocID
        data.otherDocID = otherDocID
        data.addrLine1 = addressFormData.addrLine1
        data.addrLine2 = addressFormData.addrLine2
        data.city = addressFormData.city
        data.state = addressFormData.state.name
        data.country = addressFormData.country.name
        data.pincode = addressFormData.pincode
        data.subscriptionID = subscriptionsDetails.value.subscriptionID
        console.log('data -->', data);
        // store.dispatch('KYCSignUp/addUser', {data:data})
        // router.push(`/login`);
        await $http.post(`/advisor/clients/kyc`, data)
        .then((response) => {
          console.log('response.data -->', response);
          if(response.status == 200) {
            currentStep.value = tabId
            window.open(response.data.paymentLink, '_self');
          } 
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          })
          return false;
        });
      } else {
        currentStep.value = tabId

      }
    }

    const fileUpload = async (docType, file, subscriptionID) => {
      let formData = new FormData();
      formData.append("docType", docType);
      formData.append("fileName", file);
      formData.append("subscriptionID", subscriptionID);

      return await $http.post(`/upload/file`, formData)
        .then((response) => {
          console.log('response.data -->', response);
          if(response.data) {
            return response.data.id;
          }
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          })
        });
    }

    const subscriptionsDetails = computed(() => store.getters["KYCSignUp/getSubscriptionDetails"]);
    onMounted(async () => {
      if(router.currentRoute.query.subscriptionID) {
        store.dispatch('KYCSignUp/storeSubscriptionDetails', {data: router.currentRoute.query})
      }
      if(subscriptionsDetails.value == null || subscriptionsDetails.value.subscriptionID == null) {
        router.push('/login');
      }
    });

    return {
      personalFormData,
      clientFormData,
      submitForm,
      setp,
      currentStep,
      totalStep,
      addressFormData,
      identityProofsFormData
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
